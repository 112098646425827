import { useState, isDark, TextField, useEffect, _, Fragment, useDebounce, Button } from "V3";
import {
    Table as MuiTable,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
} from "@material-ui/core";
import CircularLoader from "components/loader/CircularLoader";
import { makeStyles } from "@material-ui/styles";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@mui/material/Box';
import NestedTable from './_nestedTable';
/**
 * STYLES
 */
const useStyles = makeStyles({
    container: {
        width: "100%",
        overflowX: "auto",
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
    },
    headerRow: {
        "& th": {
            backgroundColor: ({ isDark }) => (isDark ? "#0E0E16" : "#fff"),
            padding: "20px 20px",
            whiteSpace: "nowrap",
        },
    },
    longText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "300px",
        whiteSpace: "nowrap",
        display: "block",
        paddingTop: 15,
        textTransform: "lowercase",
        boxSizing: "border-box",
    },
    allRow: {
        "&:nth-child(2n)": {
            backgroundColor: ({ isDark }) => (isDark ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.1)"),
        },
    },
    expandedRow: {
        padding: 5,
        backgroundColor: ({ isDark }) => (isDark ? '#2a2b36' : 'rgb(255, 255, 255)'),
    },
    retroRow: {
        backgroundColor: "rgba(255,0,0,0.3)",
    },
    allCell: {
        fontSize: 12,
        maxWidth: "500px",
        overflow: "visible",
        whiteSpace: "normal",
        wordBreak: "break-all",
        padding: "5px 20px",
    },
    noDataText: {
        width: "100%",
        fontSize: 24,
        fontWeight: 300,
        padding: "5px 20px",
        marginTop: 50,
    },
});

export const TableServerSide = (props) => {
    const classes = useStyles({ isDark });
    const {
        internalSearch = false,
        cellSettings,
        data = [],
        setData,
        pagination = true,
        paginationParams,
        setPaginationParams,
        totalCount,
        sorting,
        setSorting,
        sortableCells,
        setSearch,
        resetPage,
        search,
        onSearchClick,
        loading = false,
        collapsable = false,
        collapseCb = () => { },
    } = props || {};

    const [page, setPage] = useState(0);
    const rowsPerPage = paginationParams.limit;
    const [internalSearchValue, setInternalSearchValue] = useState({ search: search });
    const [openRow, setOpenRow] = useState(null);
    const [nestedData, setNestedData] = useState([]);
    const [nestedLoading, setNestedLoading] = useState(false);

    useEffect(async () => {
        if (!openRow) return;
        setNestedLoading(true)
        let data = await collapseCb(openRow);
        setNestedData(data)
        setNestedLoading(false)
    }, [openRow])

    const resetNestedData = () => setNestedData([])

    const handlePageChange = (event, newPage) => {
        let offset = newPage * rowsPerPage;
        setPaginationParams({ ...paginationParams, offset });
        setPage(newPage);
    };

    useEffect(() => {
        if (!resetPage) return;
        setPage(0);
    }, [resetPage]);

    const handleSearchChange = (val, name) => {
        setInternalSearchValue({ ...internalSearchValue, [name]: val });
    };

    const handleRequestSort = (event, property) => {
        setSorting?.((currentSorting = sorting) => {
            const isAsc = currentSorting?.orderBy === property && currentSorting?.orderByDirection === "asc";
            const newSorting = { orderByDirection: isAsc ? "desc" : "asc", orderBy: property };
            return newSorting;
        });
    };

    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    const [debouncedSearchValue] = useDebounce(internalSearchValue, 250);

    useEffect(() => {
        internalSearch && setSearch(debouncedSearchValue.search);
    }, [debouncedSearchValue]);

    const renderAllCells = () => {
        if (!data || data.length < 1) return;
        let isANewData = true;
        let newData = _.cloneDeep(data);
        _.forEach(newData, (row, rowKey) => {
            _.forEach(cellSettings, (cell) => {
                if (!cell.render) return;
                if (newData[rowKey][cell.id + "RenderedValue"]) {
                    isANewData = false;
                    return false;
                }
                newData[rowKey][cell.id + "RenderedValue"] = cell.render({ item: row });
            });
        });
        if (isANewData && !_.isEqual(newData, data)) setData(newData);
    };
    useEffect(() => renderAllCells(), [data]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onSearchClick();
        }
    };

    /**
     * RETURN
     */
    return (
        <Fragment>
            <div style={{ display: "flex", paddingTop: "5px", gap: "10px" }}>
                <TextField
                    label="Search"
                    name="search"
                    disabled={loading}
                    onChange={handleSearchChange}
                    state={internalSearchValue}
                    isClearable
                    onKeyDown={handleKeyDown}
                />
                <Button onClick={onSearchClick} disabled={loading}>Search</Button>
            </div>
            {loading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "100px",
                        height: "100vh",
                    }}
                >
                    <CircularLoader />
                </div>
            )}
            {((!cellSettings || data.length < 1) && !loading) ?
                <div className={classes.noDataText}>There isn't any data to show.</div> :
                <>
                    <TableContainer className={classes.container} style={{ marginTop: internalSearch ? 20 : 0 }}>
                        <MuiTable
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="normal"
                            aria-label="enhanced table"
                        >
                            {/** HEADER SECTION*/}
                            <TableHead>
                                <TableRow className={classes.headerRow}>
                                    {collapsable && <TableCell
                                        key={"empty"}
                                        align={"left"}
                                        padding={"normal"}
                                        style={{ width: '50px' }}
                                    />}
                                    {cellSettings.map((headCell, key) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align || "left"}
                                            padding={key === 0 ? "normal" : "none"}
                                            style={{
                                                whiteSpace: "pre-wrap",
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                                ...(headCell.width && { width: headCell.width })
                                            }}
                                            sortDirection={
                                                sorting.orderBy === headCell.id ? sorting.orderByDirection : false
                                            }
                                        >
                                            <TableSortLabel
                                                hideSortIcon={!sortableCells.includes(headCell.id)}
                                                active={sorting.orderBy === headCell.id}
                                                direction={
                                                    sorting.orderBy === headCell.id ? sorting.orderByDirection : "asc"
                                                }
                                                onClick={
                                                    headCell.id !== "actions" && sortableCells.includes(headCell.id)
                                                        ? createSortHandler(headCell.id)
                                                        : null
                                                }
                                                sx={{ textTransform: "capitalize" }}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            {/** TABLE CONTENT */}
                            <TableBody>
                                {(() => {
                                    return data.map((rowData, rowIndex) => {
                                        const labelId = `enhanced-table-checkbox-${rowIndex}`;

                                        return (
                                            <SingleRow
                                                key={rowIndex}
                                                isRetro={rowData.retro_date && rowData.retro_date !== null}
                                                rowIndex={collapsable ? rowData?.id : rowIndex}  
                                                labelId={labelId}
                                                classes={classes}
                                                setOpenRow={setOpenRow}  
                                                openRow={openRow}       
                                                resetNestedData={resetNestedData} 
                                                collapsableContent={collapsable ? <NestedTable data={nestedData} loading={nestedLoading} /> : null} 
                                                {...props}
                                            >
                                                {cellSettings.map((singleCellSettings, cellIndex) => (
                                                    <SingleCell
                                                        key={cellIndex}
                                                        classes={classes}
                                                        rowData={rowData}
                                                        rowIndex={rowIndex}
                                                        singleCellSettings={singleCellSettings}
                                                        cellIndex={cellIndex}
                                                        labelId={labelId}
                                                        style={{
                                                            ...(singleCellSettings.width && { width: singleCellSettings.width }),
                                                        }}
                                                        {...props}
                                                    />
                                                ))}
                                            </SingleRow>
                                        );
                                    });
                                })()}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                    {/*PAGINATION*/}
                    {(!pagination || totalCount <= rowsPerPage) ? null : (
                        <TablePagination
                            rowsPerPageOptions={[]}
                            showFirstButton
                            showLastButton
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => handlePageChange(event, newPage)} 
                        />
                    )}
                </>}
        </Fragment >
    );
};


export const SingleRow = ({ classes, rowIndex, children, isRetro, collapsable, openRow, setOpenRow, collapsableContent, resetNestedData = () => { } }) => {
    function handleOpenCollapse() {
        resetNestedData();
        if (rowIndex === openRow) {
            setOpenRow(null)
        } else {
            setOpenRow(rowIndex)
        }
    }

    return (
        <>
            <TableRow tabIndex={-1} key={`row-${rowIndex}`} className={isRetro ? classes.retroRow : classes.allRow}>
                {collapsable && (
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={handleOpenCollapse}
                        >
                            {rowIndex === openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                )}
                {children}
            </TableRow>
            {collapsable && <TableRow className={classes.expandedRow}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={openRow === rowIndex} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {collapsableContent}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>}
        </>
    );
};

export const SingleCell = (props) => {
    const { classes, rowData, singleCellSettings } = props || {};

    return (
        <TableCell
            align={singleCellSettings.align || "left"}
            padding={singleCellSettings.padding || "none"}
            className={classes.allCell}
            style={singleCellSettings.style}
        >
            {singleCellSettings.cellData
                ? rowData[singleCellSettings.cellData]
                : singleCellSettings.render
                    ? rowData[singleCellSettings.id + "RenderedValue"]
                    : singleCellSettings.RenderComponent
                        ? singleCellSettings.RenderComponent({ item: rowData, ...props })
                        : "-"}
        </TableCell>
    );
};
